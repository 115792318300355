import React from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NavigationBar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar">
  <Row style={{ width: '100%', alignItems: 'center' }}>
    <Col>
      <img src="/images/Screaming Fish Logo With Text.png" alt="Logo" style={{ height: '100px' }} />
    </Col>
  </Row>
  <Row style={{ width: '100%', alignItems: 'center' }}>
      <Navbar.Brand as={Link} to="/">BLACK SHADOW AND THE PROTECTORS</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">HOME</Nav.Link>
          <Nav.Link as={Link} to="/the-beginning">THE BEGINNING</Nav.Link>
          <Nav.Link as={Link} to="/characters">THE PROTECTORS</Nav.Link>
        </Nav>
      </Navbar.Collapse>
  </Row>
    </Navbar>
  );
}
