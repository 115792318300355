import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function CharacterCard({ character }) {
  return (
    <div className="card-container">
      <Link to={{ pathname: `/character/${character.id}` }} state={{ character: character }}>
        <div className="speech-bubble">
          {character.speech_text.map((text, index) => (
            <div key={index}>{text.toUpperCase()}</div>
          ))}
        </div>
        <Card.Text>
        </Card.Text>
        <Card.Img variant="top" src={character.image} />
      </Link>
    </div>
  );
}
