import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
import Characters from './components/Characters';
import Character from './components/Character';
import Beginning from './components/Beginning'

const AppRouter = () => {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/characters" element={<Characters />} />
        <Route path="/character/:id" element={<Character />} />
        <Route path="/the-beginning" element={<Beginning />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;