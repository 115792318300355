import React from 'react';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import CharacterCard from './CharacterCard';
import Footer from './Footer'

export default function Characters() {
  const characters = [
    {
      id: "the_black_shadow",
      name: 'BLACK SHADOW', description: 'TREVOR STIRLING AKA BLACK SHADOW', image: '/images/Black Shadow Sticker.png',
      image2: '/images/Black_Shadow_11x17.jpg',
	    speech_text: [
	    	"You always have", "the power to do the", "right thing"
	    ],
      speech: "You always have the power to do the right thing!",
      bio: "Trevor Stirling, aka The Black Shadow, is recruited onto the Protectors when he uses his creativity and courage to rescue people trapped in a basement by the villain Malware. Now, as the only member of the team without powers, the Black Shadow uses his new inventions to fight villains and save lives. Unlike team leader Bat Ray, Trevor sees the good in everyone and would rather try to convince villains to change their ways than defeat them."
    },
    {
      id: "water_woman",
      name: 'Water Woman', description: 'Claire Fontaine aka Water Woman', image: '/images/Water Woman Sticker.png',
      image2: '/images/Water_Woman_11x17.jpg',
	    speech_text: [
	    	"What are the", "secrets of my", "mysterious past?"
	    ],
      speech: "What are the secrets of my mysterious past?",
      bio: "Claire Fontaine, aka Water Woman, has the ability to manipulate and breathe under water. She dedicates her time to protecting the ocean and the people around it. Often feeling out of place in Urbania with the Protectors, Claire will soon discover the secrets of her mysterious past"
    },
    {
      id: "bat_ray",
      name: 'Bat Ray', description: 'Raymond "Ray" Marino aka Bat Ray', image: '/images/Bat Ray Sticker.png',
      image2: '/images/Bat_Ray_11x17.jpg',
	    speech_text: [
	    	"I will not rest", "Until I rid Urbania of crime,", "no matter what the cost!"
	    ], 
	    speech: "I will not rest until I rid Urbania of crime, no matter what the cost!",
      bio: 'In a world where no superheroes are left, Raymond "Ray" Marino, aka Bat Ray, founds the Protectors to fight for justice. He uses a metal stingray tail to channel his electrical powers. Haunted by his tragic past, Bat Ray will not rest until he rids Urbania of crime, no matter what the cost.'
    },
    {
      id: "super_tiger",
      name: 'Super Tiger', description: 'Conor "Mike" Michaels aka Super Tiger', image: '/images/Super Tiger Sticker.png',
      image2: '/images/Super_Tiger_11x17.jpg',
	    speech_text: [
	    	"I can see it now!", "The Super Tiger and his sidekick,","Black Shadow, teaming up to", "save the world!"
	    ],
      speech: "I can see it now! The Super Tiger and his sidekick, Black Shadow, teaming up to save the world!",
      bio: 'Conor "Mike" Michaels, aka The Super Tiger, is the star football player at his high school. But after developing a rare disease and undergoing an experimental treatment, Conor gains tiger-like abilities and is removed from the team. Believing his life is ruined, Mike turns to a life of crime. But can Trevor convince him to let go of his bitterness and use his powers for good?'
    }
  ];

  return (
    <Container>
      <h1 text-align="center">Meet the Protectors</h1>
      <Row>
        {characters.map(character => (
          <Col sm={3} className="mt-3 circular">
            <CharacterCard character={character} />
          </Col>
        ))}
      </Row>
      <Footer/>
    </Container >
  );
}
