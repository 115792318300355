import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col>
            <marquee behavior="scroll" direction="left">
Welcome to Screaming Fish Comics. Stay tuned for news.
            </marquee>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
