import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Footer from './Footer'

const handleClick = () => {
  window.open('https://cwsbookstore.com/categories/action/blackshadow_thebeginning/', '_blank');
};

export default function Beginning() {

  return (
    <Container>
      <Row>
        <Col className="p-3 bg-light character-card mt-3 d-flex justify-content-center align-items-center text-center">
          <Image
            width="100%"
            src='/images/the-beginning.png'
          />
        </Col>
        <Col className="p-7 bg-light character-card mt-3 d-flex justify-content-center align-items-center text-center">
          <div>
            <h1>Black Shadow: The Beginning</h1>
            <p>
	  Trevor Stirling is a genius inventor. So why does his boss want to fire him? After all his latest inventions backfire, Trevor is determined to prove he is not a failure. But is he really meant to work at a restricting company like Grapefruit Tech, or will he use his imagination and quick thinking for something greater? When two of the city’s leading journalists are kidnapped, Trevor Stirling is forced to decide. Read the origin of Trevor Stirling aka the Black Shadow in this 70-page graphic novel. Then read more of his adventures in Black Shadow and the Protectors, a new comic book series.
	  </p>
            
	  <p>
              <Button variant="primary" className="orange-background" onClick={handleClick}>BUY NOW</Button>
            </p>
          </div>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
