import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

export default function Character() {
  const location = useLocation();
  const character = location.state.character;

  return (
    <Container>
      <Row>
        <Col className="p-3 bg-light character-card mt-3 d-flex justify-content-center align-items-center text-center">
          <Image
            width="100%"
            src={character.image2}
          />
        </Col>
        <Col className="p-5 bg-light character-card mt-3 d-flex justify-content-center align-items-center text-center">
          <div>
            <h1>{character.name}</h1>
            {character.bio}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
