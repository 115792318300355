import React from 'react';
import AppRouter from './AppRouter';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <Container>
        <AppRouter />
      </Container>
    </div>
  );
}

export default App;