import React, { useState, useEffect, useRef } from 'react';
import { Container, Button } from 'react-bootstrap';
import Footer from './Footer'

export default function Home() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, []);

  return (
    <Container>
      <h1 text-align="center">Black Shadow and the Protectors</h1>
      {/* <p>
        In a world full of injustice, you can count on the Black Shadow and the Protectors to make things right!.
      </p> */}
      {/* <p>
        <Button variant="primary">Learn more</Button>
      </p> */}
      <header className="App-header">
        <video
          ref={videoRef}
          style={{ position: "relative", width: "100%", height: "100%" }}
          src='/videos/Blackshadow Promo-1.m4v'
        autoPlay 
          loop
        // muted
        />
        <button onClick={handlePlayPause}>
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </header>
      <Footer />
    </Container>
  );
}
